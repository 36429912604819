import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/references.scss"
import { Link } from "gatsby"

export default function projekter() {
  return (
    <Layout>
      <Seo title="Referencer" />
      <div className="references">
        <h1>Samarbejdspartnere</h1>
        <p>
          Kontakt evt mine samarbejdspartnere, hvis du vil høre mere om mig:
        </p>
        <div className="collabs">
          <a
            target="_blank"
            rel="noreferrer"
            href="http://frankslemming.dk/"
            aria-label="frankslemming.dk"
          >
            <StaticImage
              src="../images/references/collab/collab1.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="frankslemming.dk"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.lightscapes.dk/"
            aria-label="www.lightscapes.dk"
          >
            <StaticImage
              src="../images/references/collab/collab2.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="lightscapes.dk"
              style={{ filter: "invert(.8) " }}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.2r-arkitekter.dk/"
            aria-label="Link til 2R arkitekter"
          >
            <StaticImage
              src="../images/references/collab/collab3.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="2R arkitekter"
            />
          </a>
        </div>
        <p>Jeg formidler også gerne kontakt til de enkelte bygherrer.</p>
        <Link to="/kontakt/">
          <p>Kontakt mig her</p>
        </Link>

        <h2>Medieomtale</h2>
        <div className="media">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.danskeboligarkitekter.dk/find-projekt/projekt/vis/arkitekttegnet-traehus-paa-budget/"
            aria-label="danskeboligarkitekter.dk"
          >
            <StaticImage
              src="../images/references/media/media1.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="danskeboligarkitekter.dk"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.danskeboligarkitekter.dk/find-projekt/projekt/vis/staal-glas-og-masser-af-hjemlig-hygge/"
            aria-label="danskeboligarkitekter.dk"
          >
            <StaticImage
              src="../images/references/media/media1.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="danskeboligarkitekter.dk"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.dr.dk/drtv/se/hammerslag_-energimaerkning-_-f-til-a_146655?fbclid=IwAR3ySJAYmam8tAGHIkKnkVQB6FqV-_ElMkHRTra0-qYTQ-UUSwpIz4Y6hw0"
            aria-label="Danmarks radio - hammerslag"
          >
            <StaticImage
              src="../images/references/media/media2.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="Danmarks radio - hammerslag"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://bobedre.dk/boliger/huse/selvbygget-er-velbygget"
            aria-label="Bobedre"
          >
            <StaticImage
              src="../images/references/media/media3.png"
              width={100}
              height={"auto"}
              quality={100}
              alt="Bobedre"
            />
          </a>
        </div>
      </div>
    </Layout>
  )
}
